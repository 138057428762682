<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="80"
    >
      <land-img
        v-if="logoSrc"
        :src="logoSrc"
        contain
        max-width="208"
        width="184"
        height="64"
      />

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            :to="{name: item.name}"
            :exact="item.name === 'Home'"
            :ripple="false"
            class="font-weight-bold text-subtitle-1"
            min-width="96"
            text
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  import app from '@/api/co.app'
  import config from '@/api/co.config'
  const SiteKeys = config.comm.siteKeys

  export default {
    name: 'HomeAppBar',
    components: {
      HomeDrawer: () => import('./Drawer'),
    },
    data () {
      return {
        drawer: null,
        logoSrc: '',
        items: [
          {
            name: 'Home',
            title: '首页'
          }, {
            name: 'Notice',
            title: '资讯中心'
          }, {
            name: 'Park',
            title: '魅力园区'
          }, {
            name: 'Policy',
            title: '创新政策'
          }
          // , {
          //   name: 'Disclose',
          //   title: '信息公开'
          // }, {
          //   name: 'Contact',
          //   title: '联系我们'
          // }
        ],
      }
    },
    created () {
      this.logoSrc = this.getLogo()
    },
    methods: {
      getLogo () {
        /* logo 比例默认为 520 * 180 */
        const param = config.getSiteParam(SiteKeys.logo)
        return param.value || `${app.base.res_skin}/assist/logo-web.png`
      }
    }
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
